
import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import SelectSurvey from '../surveys/SelectSurvey';
import { Event } from '../../models/types';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Event;
  index: number;
  children: React.ReactNode;
  updateFunc : Function;
  templates? : Array<string>;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  defaultChecked,
  updateFunc,
  templates,
  ...restProps
}) => {
  let inputNode = inputType === 'number' ? <InputNumber controls={false} className='config-num' 
                                                onChange={(value : number | null) => updateFunc([record.id, dataIndex], dataIndex, value)}/> 
                                                : <Input onChange={(event : any) => updateFunc([record.id, dataIndex], dataIndex, event.target.value)} />;

  inputNode = dataIndex === 'survey_id' ? <SelectSurvey selectOptions={templates!}
                                                        updateFunc={updateFunc!}
                                                        form_name={[record.id, dataIndex]}
                                                        initialValue={record.survey_id}
                                                        /> : inputNode;

  return (
      <td {...restProps}>
          {editing ? (
              <Form.Item
                  name={[record.id, dataIndex]}
                  style={{ margin: 0 }}
                  rules={[
                      {
                          required: defaultChecked,
                          message: `Please Input ${title}!`,
                      },
                  ]}
              >
                  {inputNode}
              </Form.Item>
          ) : (
              children
          )}
      </td>
  );
};

export default EditableCell;
import User from "./UserModel";

export default class Study {
  study_id : number;
  name : string;
  description : string;
  created_by : User;
  created_on : Date;

  clinicians : Array<User>;
  participants : Array<User>;

  config? : any;

  user_role : "clinician" | "creator";

  constructor(data : any) {
    this.study_id = data["id"];
    this.name = data["name"];
    this.description = data["description"];
    this.created_by = new User(data["created_by"]);
    this.created_on = new Date(data["created_on"]);
  
    this.clinicians = data["clinicians"].map((c : any) => new User(c));
    this.participants = data["participants"].map((p : any) => new User(p));

    this.user_role = data['user_role'];
    
  }
}
import React from 'react';
import { Button, Result } from 'antd';
import AppLayout from './AppLayout';
import User from '../models/UserModel';

interface ViewProps {
  current_user : User;
}

const Error404: React.FC<ViewProps> = ({current_user} : ViewProps) => (
    <AppLayout crumbs={[]} current_user={current_user}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited either does not exist or you do not have access."
        extra={<Button type="primary" href='/'>Back Home</Button>}
      />
  </AppLayout>
);

export default Error404;
import React from 'react';

import User from '../models/UserModel';

import AppLayout from './AppLayout';

import '../App.css';
import * as API from '../api';
import { Button, Form, Input } from 'antd';

interface StudyState {
  username: string;
  password: string;
  error: string;
}

interface ViewProps {
  current_user: User;
}

export default class LoginView extends React.Component<ViewProps, StudyState> {
  constructor(props: any) {
    super(props);
    this.state = { username: "", password: "", error: "" };
  }

  login = async () => {
    let response = await API.login(this.state.username, this.state.password);

    if (response.ok) { window.location.href = '/' }
    else {
      let error = await response.json();
      this.setState({ error: error })
    }
  }

  render() {

    return (
      <AppLayout crumbs={["Login"]}
        current_user={this.props.current_user}>

        <Form
          name="basic"
          className='login-form'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
        >
          <h2>Log In</h2>
          <Input placeholder='username' value={this.state.username} onChange={e => this.setState({ username: e.target.value })} />
          <Input placeholder='password' type='password' value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
          <span className='login-error'>{this.state.error}</span>
          <Button type='primary' onClick={this.login}>Login</Button>

          <a href='/sso/login'><Button>Login with GN-AS SSO</Button></a>
          <div style={{ opacity: 0.7, fontSize: '10pt', marginTop : '10px'}}>Need an account? <a href="/accounts/signup/">signup</a></div>
        </Form>

      </AppLayout>
    );
  }
}


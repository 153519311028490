import '../../App.css';
import User from '../../models/UserModel';
import { Card, Descriptions, DescriptionsProps } from 'antd';
import Study from '../../models/StudyModel';
import { DateText } from '../DateText';
import { StudyNameBadge } from '../studies/StudyNameBadge';
import UserOTPModal from './UserOTPModal';
import StudyConfigModal from '../studies/StudyConfigModal';


interface UserInfoProps {
  user : User;
  study? : Study;
  updateConfig : Function;
}

const UserInfo = (props : UserInfoProps) => {

  let items : DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Username',
      span : 1,
      children: props.user.username,
    },
    {
      key: '2',
      label: 'Total surveys',
      span : 1,
      children: props.user.num_surveys,
    },
    {
      key: '3',
      label: 'Status',
      children: props.user.status ?? "",
    },
    {
      key: '4',
      label: 'Study Schedule',
      span : 2,
      children: "",
    },
    {
      key: '5',
      label: 'Participant in',
      span : 1,
      children: props.user.participant_in.map(e => <StudyNameBadge name={e['name']} url={`?study_id=${e['id']}`} key={e['id']}/>),
    },
  ]

  if (props.study) {
    let text;
    let config_button;

    if (!props.study.config) {
      text = "not configured";
      config_button = <StudyConfigModal study={props.study} user={props.user} 
                                        updateConfig={props.updateConfig}
                                        modal_header={`Add Personalized Config for ${props.user.full_name}`}
                                        button_text='Personalize schedule'/>
    } else if (props.study.config && props.study.config.participant_id) {
      text = <>{props.study.config.events.length} events &nbsp;<i className='personalized'>(personalized)</i></>;
      config_button = <StudyConfigModal study={props.study} user={props.user} 
                                        updateConfig={props.updateConfig}
                                        modal_header={`Edit Personalized Config for ${props.user.full_name}`}
                                        button_text='Edit'/>
    } else {
      text = `${props.study.config.events.length} events`;
      config_button = <StudyConfigModal study={props.study} user={props.user} 
                                        updateConfig={props.updateConfig}
                                        modal_header={`Add Personalized Config for ${props.user.full_name}`}
                                        button_text='Personalize schedule'/>
    }

    items[3] = {
      key: '4',
      label: 'Study Schedule',
      span : 2,
      children: <span className='user-schedule'>{text} {config_button}</span>,
    };
  } else {
    items.splice(3, 1);
  }

  return (
    <>
      <Card
        className='user-info'
        style={{ width: '100%' }}
        title={<div className='user-heading'> <h2>{props.user.full_name} </h2> 
                  <span className='last-active'>
                    last active <DateText date={new Date(props.user.last_active ?? 0)} fallback='n/a'/>
                    <UserOTPModal user={props.user}/>
                  </span>
               </div>}
      >
        <Descriptions items={items} column={3}/>
      </Card>
    </>
  );
};

export default UserInfo;

import React from 'react';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

interface OptionsProps {
  exportHealth : Function;
  exportSurveys : Function;
}

const UserDataDownload: React.FC<OptionsProps> = ({exportHealth, exportSurveys} : OptionsProps) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div onClick={() => exportHealth()}>Export health data</div>
    },
    {
      key: '2',
      label: <div onClick={() => exportSurveys()}>Export survey data</div>
    }
  ];

  return (<Dropdown menu={{ items }} className='study-dropdown'>
            <Button><DownloadOutlined/>Export</Button>
          </Dropdown>)
}

export default UserDataDownload;
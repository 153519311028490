import moment from 'moment';


interface BadgeProps {
    name : string;
    url : string;
}

var stringToColor = (string : string, saturation = 85, lightness = 75) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
}

export const StudyNameBadge = ({name, url}: BadgeProps) => {
    return <div className='study-badge' style={{backgroundColor : `${stringToColor(name)}`}}><a href={url}>{name}</a></div>
}
    

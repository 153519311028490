import React from 'react';

import AppLayout from './AppLayout';
import Study from '../models/StudyModel';
import ListOfStudies from './ListOfStudies';

import '../App.css';
import * as API from '../api';
import User from '../models/UserModel';


interface AllStudiesState {
  studies : Array<Study>;
}

interface ViewProps {
  current_user : User;
}


export default class AllStudiesView extends React.Component<ViewProps, AllStudiesState> {
  constructor(props : any) {
    super(props);
    this.state = { studies : []};
  }

  async componentDidMount() {
    let response = await API.getUserStudies();
    let studies_data = await response.json();

    let studies = studies_data["studies"].map((e : any) => new Study(e));

    this.setState({ studies : studies });
  }

  render() {
    return (
      <AppLayout crumbs={["My Studies"]}
                 selectedMenu={"studies"}
                 current_user={this.props.current_user}>
        <ListOfStudies data={this.state.studies}/>
      </AppLayout>
    );
  }
}



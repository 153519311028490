import React, { useState } from 'react';
import '../../App.css';
import User from '../../models/UserModel';
import { Card } from 'antd';
import HealthDataTable from './HealthDataTable';
import LogDataTable from './LogDataTable';
import SurveyDataTable from './SurveyDataTable';
import Study from '../../models/StudyModel';
import UserDataDownload from './UserDataDownload';
import UserOverview from './UserOverview';


interface UserDataProps {
  user : User;
  study? : Study;
  exportHealth : Function;
  exportSurveys : Function;
}

const tabList = [
  {
    key: 'overview',
    tab: 'Overview',
  },
  {
    key: 'health',
    tab: 'Health Data',
  },
  {
    key: 'surveys',
    tab: 'Surveys',
  },
  {
    key: 'logs',
    tab: 'Logs',
  },
];


const UserDataTabs = (props : UserDataProps) => {
  const [activeTabKey, setActiveTabKey] = useState<string>('overview');

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const contentList: Record<string, React.ReactNode> = {
    overview : <UserOverview user_id={props.user.user_id} study={props.study}/>,
    health: <HealthDataTable user_id={props.user.user_id} study={props.study}/>,
    logs: <LogDataTable user_id={props.user.user_id} study={props.study}/>,
    surveys : <SurveyDataTable user_id={props.user.user_id} study={props.study}/>
  };

  return (
    <>
      <Card
        style={{ width: '100%', marginBottom: 100 }}
        type='inner'
        tabList={tabList}
        activeTabKey={activeTabKey}
        tabBarExtraContent={<UserDataDownload exportHealth={props.exportHealth} exportSurveys={props.exportSurveys}/>}
        onTabChange={onTabChange}
        bordered={false}
      >
        {contentList[activeTabKey]}
      </Card>
    </>
  );
};

export default UserDataTabs;

import { useEffect, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';

import '../../App.css';
import { Table } from 'antd';
import { DateText } from '../DateText';
import Survey from '../../models/SurveyModel';
import SurveyView from '../surveys/SurveyView';
import Study from '../../models/StudyModel';
import * as API from '../../api';
import GridSurvey from '../surveys/GridSurvey';


interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface DataTableProps {
  user_id : string;
  study? : Study;
}

const SurveyDataTable = (props : DataTableProps) => {
  const [survey_data, setData] = useState<Survey[]>();
  const [sort, setSort] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 100,
      total : 0,
      showTotal: total => `${total} total`,
      showSizeChanger : false
    },
    filters : {"data_label" : []}
  });

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const fetchData = async () => {
    setLoading(true);

    let survey_response = await API.getUserSurveys({id : props.user_id, page : tableParams.pagination?.current,
                                                    max_items : tableParams.pagination?.pageSize, 
                                                    sort : sort});
    
    let survey_data = await survey_response.json();

    setData(survey_data['surveys'].map((s : any) => new Survey(s)));
    
    setLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: survey_data.total_items,
      },
    });
  };

  const createSortParam = (sort : SorterResult<Survey> | SorterResult<Survey>[]) => {
    let newSorting : SorterResult<Survey>;
    if (Array.isArray(sort)) newSorting = sort[0];
    else newSorting = sort;

    if (newSorting.order === undefined) {
      return '';
    }
    else if (newSorting.order === 'ascend') {
      return `${newSorting.field}`
    } 
    else if (newSorting.order === 'descend') {
      return `-${newSorting.field}`
    }
    else {
      return '';
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Survey> | SorterResult<Survey>[]) => {
      setTableParams({
        pagination,
        ...filters,
        ...sorter,
      });

      let newSort = createSortParam(sorter);

      setSort(newSort);

      if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        setData([]);
      }
  };

  const columns: ColumnsType<Survey> = [
    {
      title: 'Survey',
      render: s => s.template_id === 'hj.checkin.context' ? <GridSurvey survey={s}/> : s.template_id
    },
    {
      title: 'Created On',
      dataIndex : 'created_on',
      sorter: true,//(a, b) => new Date(a.created_on!).getTime() - new Date(b.created_on!).getTime(),
      render: (o : string) => <DateText date={new Date(o)}/>
    },
  ];


  return (
    <Table columns={columns} rowKey="created_on" loading={loading} dataSource={survey_data}
           onChange={handleTableChange} size={'small'} pagination={tableParams.pagination}
           
           expandable={{ rowExpandable: (r) => !r.template_data ,
                         expandedRowRender : r => <SurveyView survey={r}/> }}/>
  );
}

export default SurveyDataTable;

import React from 'react';
import './App.css';
import * as API from './api';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllUsersView from './components/AllUsersView';
import AllStudiesView from './components/AllStudiesView';
import StudyView from './components/studies/StudyView';
import UserView from './components/participants/UserView';
import Error404 from './components/Error404';
import User from './models/UserModel';
import LoginView from './components/LoginView';
import ManageTemplatesView from './components/surveys/ManageTemplatesView';

interface AppState {
  current_user? : User
}

export class App extends React.Component<{}, AppState> {
  constructor(props : any) {
    super(props);
    this.state = {  };
  }

  async componentDidMount() {
    let response = await API.getCurrentUser();
    let current_user = await response.json();

    if (current_user["anonymous"] === true && window.location.pathname !== '/login/') { window.location.href = '/login/' }
    else { this.setState({current_user : new User(current_user)}); }
  }

  render() {
    if (this.state.current_user === undefined) return null;

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AllStudiesView current_user={this.state.current_user}/>}/>
          <Route path="/login/" element={<LoginView current_user={this.state.current_user}/>}/>
          <Route path="/users/" element={<AllUsersView current_user={this.state.current_user}/>}/>
          <Route path="/user/:id" element={<UserView current_user={this.state.current_user}/>}/>
          <Route path="/studies" element={<AllStudiesView current_user={this.state.current_user}/>}/>
          <Route path="/study/:id" element={<StudyView current_user={this.state.current_user}/>}/>
          <Route path="/surveys" element={<ManageTemplatesView current_user={this.state.current_user}/>}/>
          <Route path='*' element={<Error404 current_user={this.state.current_user}/>}/>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;

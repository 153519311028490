import React from 'react';

import { Modal } from 'antd';

import '../../App.css';
import * as API from '../../api';
import Study from '../../models/StudyModel';
import { StudyCode } from '../../models/types';

import { Typography } from 'antd';
import Loader from '../Loader';

const { Text } = Typography;



interface StudyInviteModalState {
  visible: boolean;
  code?: StudyCode;
}

interface StudyInviteModalProps {
  study: Study;
}

export default class StudyInviteModal extends React.Component<StudyInviteModalProps, StudyInviteModalState> {
  loading: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };

    this.loading = false
  }

  showModal = () => {
    this.getCode();
    this.setState({ visible: true });
  }

  hideModal = () => {
    this.setState({ visible: false });
  }

  getCode = async () => {
    if (this.loading) return
    
    this.loading = true;
    this.forceUpdate();

    let response = await API.getInviteCode(this.props.study.study_id);
    
    if (response.ok) {
      let data = await response.json();
      this.setState({ code : data });
    } else {
      this.loading = false;
      this.forceUpdate();
    }
  }

  render() {
    let body;

    if (!this.state.code) {
      body = <Loader/>
    } else {
      body = <div className='invite-modal-body'>
              <>Code: <Text keyboard copyable>{this.state.code.code}</Text></><br/>
              <>Created: <Text keyboard>{this.state.code.created_on}</Text></><br/>
              <>Expiration: <Text keyboard>{this.state.code.expiration}</Text></><br/>
            </div>

    }

    return (
      <>
        <div onClick={this.showModal}>View invite code</div>
        <Modal
          open={this.state.visible}
          title="Invite code"
          onCancel={this.hideModal}
          footer={null}
        >
          <p className='config-modal-description'>Provide the following code to clinician give them access to study.</p>
          {body}
        </Modal>
      </>
    );
  }
}



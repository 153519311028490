import React from 'react';

import { Button, Modal, Popconfirm, UploadProps } from 'antd';

import '../../App.css';
import * as API from '../../api';
import User from '../../models/UserModel';
import Study from '../../models/StudyModel';
import StudyConfig from './StudyConfig';
import { Event } from '../../models/types';
import { DeleteOutlined } from '@ant-design/icons';


interface ConfigModalState {
    visible: boolean;
    config?: any;
    error: string;
    templates: Array<string>;
}

interface ConfigModalProps {
    user?: User;
    study: Study;
    button_text : string;
    modal_header : string;
    updateConfig: Function;
}

export default class StudyConfigModal extends React.Component<ConfigModalProps, ConfigModalState> {
    loading: boolean;

    static defaultProps = {
        chooseStudy: false
    };

    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            error: '',
            templates: []
        };

        this.loading = false
    }


    showModal = async () => {
        let response1 = await API.getStudyConfig(this.props.study.study_id, this.props.user?.user_id);
        let response2 = await API.getAllSurveyTemplateIDs();

        this.loading = true;
        if (response1.ok && response2.ok) {
            let config_data = await response1.json();
            let template_data = await response2.json();
            this.setState({ config: config_data, templates: template_data });

        } else if (response2.ok) {
            let template_data = await response2.json();
            this.setState({ config: {   events : [], 
                                        study_id : this.props.study.study_id,
                                        participant_id : this.props.user?.user_id,
                                        new : true}, 
                            templates: template_data });
        }

        this.loading = false;
        this.setState({ visible: true, error : '' });
    }

    saveConfig = async (events : Array<Event>) => {
        console.log('save2', events)
        if (this.state.config.new && this.props.user?.user_id) { this.saveNewConfig(events); } // save as new for user
        else if (this.state.config.new) { this.saveNewConfig(events); } // save as new for study
        else if (this.state.config.participant_id && this.props.user?.user_id) { this.updateConfig(events); } // update user config
        else if (this.props.user?.user_id) { this.saveNewConfig(events); } // save as new for user
        else { this.updateConfig(events); } // update study config
    }

    saveNewConfig = async (events : Array<Event>) => {
        let updated_config = {...this.state.config!}
        updated_config['events'] = events;

        let response = await API.createNewConfig(updated_config, this.props.study.study_id, this.props.user?.user_id);

        if (response.ok) {
            let config_data = await response.json();
            this.setState({ config: config_data, error : '' }, () => this.props.updateConfig(config_data));
        } else {
            // TODO: error
            this.setState({ error : 'There was an issue uploading the config. Please check that it is formatted properly.' });
        }
    }

    updateConfig = async (events : Array<Event>) => {

        let updated_config = {...this.state.config!}
        updated_config['events'] = events;

        let response = await API.updateConfig(updated_config, this.props.study.study_id, this.props.user?.user_id);

        if (response.ok) {
            let config_data = await response.json();
            this.setState({ config: config_data, error : '' }, () => this.props.updateConfig(config_data));

        } else {
            // TODO: error
            this.setState({ error : 'There was an issue updating the config. Please check that it is formatted properly.' });
        }
    }

    fetchConfig = async () => {
        if (!this.props.study.config.participant_id) return undefined;

        let config_data;
        try {
          let config_response = await API.getStudyConfig(this.props.study.study_id, this.props.user?.user_id);
          if (config_response.ok) config_data = await config_response.json();
        } catch (e) {
          config_data = undefined;
        }
    
        return config_data
      }

    removeConfig = async () => {
        let delete_response = await API.removeStudyConfig(this.props.study.study_id, this.props.user?.user_id);
        //let config_response = await this.fetchConfig();
        let new_config = await this.fetchConfig();

        if (delete_response.ok && !new_config) {

            this.setState({ config: {   events : [], 
                study_id : this.props.study.study_id,
                participant_id : this.props.user?.user_id,
                new : true}, error : '' }, () => this.props.updateConfig(undefined));
        } else if (delete_response.ok) {
            this.setState({ config: new_config, error : '' }, () => this.props.updateConfig(new_config));
        } 
        
        else {
            // TODO: error
            this.setState({ error : 'There was an issue deleting the config.' });
        }
    }

    hideModal = () => {
        this.setState({ visible: false })
    }

    render() {
        if (!this.state.config) return <Button onClick={this.showModal} 
                                               className='code-modal-btn edit-button'
                                        >{this.props.button_text}</Button>
        const props: UploadProps = API.generateUploadProps(this.props.study.study_id, this.props.user?.user_id);

        let description;
        let label;

        if (this.state.config.new && this.props.user?.user_id) { 
            description = "Create a personalized config for this user. This applies to only this participant and will not affect other users in the study."
         } // save as new for user
        else if (this.state.config.new) { 
            description = "Create a new config for the entire study. This config applies to all participants without their own personalized configs."
         } // save as new for study
        else if (this.state.config.participant_id && this.props.user?.user_id) { 
            description = "Update the personalized config for this user. This will overwrite the existing personalized config, but will not affect other participants in the study."
        } // update user config
        else if (this.props.user?.user_id) { 
            description = "Create a personalized config for this user. This applies to only this participant and will not affect other participants in the study."
        } // save as new for user
        else { 
            description = "Update the config for the entire study. This will overwrite the existing study config, but will not affect users with their own personalized configs"
        } // update study config

        let delete_button;
        if (!this.state.config.new && this.state.config.participant_id && this.props.user) {
            // showing customized config for XX
            label = <>Showing personalized config for participant <b>{this.props.user.full_name}</b></>;
            delete_button = <Popconfirm title="Delete personalized schedule?" description="This will revert back to study schedule."  onConfirm={() => this.removeConfig()}>
                                <Button danger><DeleteOutlined /> Delete</Button>
                            </Popconfirm>
        }
        else if (!this.state.config.new && this.props.user) {
            // showing config for study
            label = <>Showing config for study <b>{this.props.study.name}</b></>
        } 
        else if (!this.state.config.new) {
            // showing config for study
            label = <>Showing config for study <b>{this.props.study.name}</b></>
            delete_button = <Popconfirm title="Delete config?" onConfirm={() => this.removeConfig()}>
                                <Button danger><DeleteOutlined /> Delete</Button>
                            </Popconfirm>
        }

        return (
            <>
                <Button onClick={this.showModal} className='code-modal-btn edit-button'>{this.props.button_text}</Button>
                <Modal
                    open={this.state.visible}
                    title={this.props.modal_header}
                    onCancel={this.hideModal}
                    footer={null}
                    width='75%'
                >
                    <span className='config-info'>
                        {/*<p style={{ fontStyle: 'italic'}}>{label}</p>*/}
                        <p className='config-modal-description'>{description}</p>
                    </span>

                    <p style={{color: '#d9363ed1'}}>{this.state.error}</p>

                    <StudyConfig events={this.state.config['events']} saveConfig={this.saveConfig}
                                 uploadProps={props} templates={this.state.templates} 
                                 delete_button={delete_button}/>
                </Modal>
            </>
        );
    }
}



import '../../App.css';
import { Card } from 'antd';

import React from 'react';
import Loader from '../Loader';


interface SurveyViewProps {
    template_data : any;
}

export default class SurveyTemplateView extends React.Component<SurveyViewProps, {}> {

    parse_results = () => {        
        let elements = [];

        for (let question of this.props.template_data["data"]["tasks"]) {
            switch(question["type"]) { 
                case "Instruction": {
                    elements.push(<div className='survey-step single-choice' key={question["ID"]}>
                                    <h3>{question['title']}</h3>
                                    <p> {question['detailText']}</p>
                                    <hr/>
                                  </div>)
                    break;                }
                case "Completion": {
                    elements.push(<div className='survey-step single-choice' key={question["ID"]}>
                                    <h3>{question['title']}</h3>
                                    <p> {question['detailText']}</p>
                                    <hr/>
                                  </div>)
                    break;                }
                case "QuestionSingleChoice": { 
                    elements.push(<div className='survey-step single-choice' key={question["ID"]}>
                                    <h3>{question['title']}</h3>
                                    <p> {question['detailText']}</p>
                                        {question['choices'].map((e : any) => <p className='choice' key={e}>{e}</p>)}
                                    <hr/>
                                  </div>)
                    break;
                } 
                case "QuestionMultiChoice": { 
                    elements.push(<div className='survey-step single-choice' key={question["ID"]}>
                                    <h3>{question['title']}</h3>
                                    <p> {question['detailText']}</p>
                                        {question['choices'].map((e : any) => <p className='choice' key={e}>{e}</p>)}
                                    <hr/>
                                  </div>)
                    break;
                } 
                case "QuestionText": { 
                    elements.push(<div className='survey-step text' key={question["ID"]}>
                                    <h3>{question['title']}</h3>
                                    <p> {question['detailText']}</p>
                                    <p className='choice'>Short answer</p>
                                    <hr/>
                                    </div>)
                    break;
                } 
                case "QuestionScale": { 
                    elements.push(<div className='survey-step scale' key={question["ID"]}>
                                    <h3>{question['title']}</h3>
                                    <p> {question['detailText']}</p>
                                    <p> {question['scaleMin']} ({question['scaleMinText']}) to {question['scaleMax']} ({question['scaleMaxText']})
                                    </p>
                                    <hr/>
                                    </div>)
                    break;
                } 
                default: { 
                    elements.push(<div className='survey-step scale' key={question["ID"]}>
                                    <h3>{question['title']}</h3>
                                    {`Unimplemented type ${question["type"]}`}
                                    <p> {question['detailText']}</p>
                                    <hr/>
                                    </div>)
                    break;
                } 
            }
        }
        return elements;
    }


    render() {
        if (!this.props.template_data) return <Loader/>
        return (
            <>
              <Card
                className='user-info'
              >
                {this.parse_results()}
              </Card>
            </>
          );
    }

};


export default class User {
    full_name : string;
    username: string;
    user_id : string;
    role : number;

    last_active? : Date;
    num_surveys : number;
    participant_in : Array<any> = [];

    config_personalized : boolean;

    anonymous : boolean;

    status? : string;
    active_study? : number;
    created_by? : string;
 
    constructor(data : any) {
        this.full_name = data["first_name"];
        this.username = data["username"];
        this.user_id = data["user_id"];
        this.role = data["role"];

        this.last_active = new Date(data["last_active"]);
        this.num_surveys = data["num_surveys"];
        this.participant_in = data["participant_in"];

        this.anonymous = data["user_id"] ? false : true;

        this.config_personalized = data["config_personalized"] ?? false;

        if (data["details"]) {
            this.status = data["details"]["status"];
            this.active_study = data["details"]["active_study"];
            this.created_by = data["details"]["created_by"];
        }
    }

    get_data = async () => {
        
    }

    export_data = async () => {
        
    }

    get_logs = async () => {
        
    }

    get_surveys = async () => {
        
    }

    export_surveys = async () => {
        
    }
}
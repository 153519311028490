import React from 'react';
import { Select } from 'antd';

const onChange = (value: string) => {
    console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
    console.log('search:', value);
};

// Filter `option.label` match the user type `input`
const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


interface SelectProps {
    selectOptions: Array<string>;
    updateFunc : Function;
    form_name : Array<any>;
    initialValue? : string;
}

const SelectSurvey: React.FC<SelectProps> = ({ selectOptions, updateFunc, form_name, initialValue }: SelectProps) => (
    <Select
        showSearch
        placeholder="Select survey"
        defaultValue={initialValue}
        optionFilterProp="children"
        onChange={(value : string) => updateFunc(form_name, form_name[1], value)}
        onSearch={onSearch}
        filterOption={filterOption}
        options={selectOptions.map(i => { return { value: i, label: i } })}
    />
);

export default SelectSurvey;
import { useEffect, useState } from 'react';
import '../../App.css';
import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { HealthData } from '../../models/types';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';

import * as API from '../../api';
import Study from '../../models/StudyModel';
import { DateText } from '../DateText';

interface HealthDataTableProps {
  user_id : string;
  study? : Study;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const HealthDataTable = (props : HealthDataTableProps) => {
  const [data, setData] = useState<HealthData[]>();
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 100,
      total : 0,
      showTotal: total => `${total} total`,
      showSizeChanger : false
    },
    filters : {"data_label" : []}
  });

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const fetchData = async () => {
    setLoading(true);
    let health_response = await API.getUserData({id : props.user_id, 
                                                 study_id : props.study?.study_id, 
                                                 page : tableParams.pagination?.current, 
                                                 max_items : tableParams.pagination?.pageSize,
                                                 fields : selectedFilters,
                                                 sort : sort});
    
    let health_data = await health_response.json();

    if (filters.length === 0) setFilters(health_data.all_fields);
    setData(health_data.data);
    
    setLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: health_data.total_items,
      },
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<HealthData> | SorterResult<HealthData>[]) => {
      setTableParams({
        pagination,
        ...filters,
        ...sorter,
      });

      let newFilters = filters['data_label'] as any ?? []; // TODO: kind of hacky, change

      let newSort = createSortParam(sorter);

      setSort(newSort);

      if (newFilters !== selectedFilters) {
        setSelectedFilters(newFilters);
      }
      
      // `dataSource` is useless since `pageSize` changed
      if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        setData([]);
      }
  };

  const createSortParam = (sort : SorterResult<HealthData> | SorterResult<HealthData>[]) => {
    let newSorting : SorterResult<HealthData>;
    if (Array.isArray(sort)) newSorting = sort[0];
    else newSorting = sort;

    if (newSorting.order === undefined) {
      return '';
    }
    else if (newSorting.order === 'ascend') {
      return `${newSorting.field}`
    } 
    else if (newSorting.order === 'descend') {
      return `-${newSorting.field}`
    }
    else {
      return '';
    }
  }

  
  const columns: ColumnsType<HealthData> = [
    {
      title: 'Type',
      dataIndex: 'data_label',
      filters : filters.map((e : string) => { return {text : e, value : e} }),
      defaultFilteredValue : filters,
      sorter: (a, b) => a.data_label.localeCompare(b.data_label),
    },
    {
      title: 'Value',
      render: (o : HealthData) => <>{o.data_value} {o.data_unit}</>
    },
    {
      title: 'Start Time',
      dataIndex : 'start_time',
      sorter: true, //(a, b) => new Date(a.start_time!).getTime() - new Date(b.start_time!).getTime(),
      render: (o : string) => <DateText date={new Date(o)} />
    },
    {
      title: 'End Time',
      dataIndex : 'end_time',
      sorter: true, //(a, b) => new Date(a.end_time!).getTime() - new Date(b.end_time!).getTime(),
      render: (o : string) => <DateText date={new Date(o)} />
    },
    
  ];


  return (
    <Table rowKey={'id'} 
           columns={columns} 
           dataSource={data} 
           loading={loading} 
           onChange={handleTableChange}
           pagination={tableParams.pagination}
           size={'small'}
           />
  );
}

export default HealthDataTable;

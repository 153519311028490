import '../../App.css';
import Survey from '../../models/SurveyModel';
import React from 'react';
import { GridCategoryName, grid_categories } from '../../models/types';


interface SurveyViewProps {
    survey : Survey;
}



export default class GridSurvey extends React.Component<SurveyViewProps, {}> {
    render = () => { 
        try {
            let responses = this.props.survey.data;

            // get all responses to context questions
            console.log(this.props.survey)

            let contexts : string[] = [];

            for (let question_template of this.props.survey.template_data) {

                let question_response = responses.find((i : any) => i['identifier'] === question_template["ID"]);

                // question left blank or not rendered based on rules
                if (!question_response) continue;
                if (question_response['choiceAnswers']) {
                    const indices = question_response['choiceAnswers'] ? question_response['choiceAnswers'] : question_response['booleanAnswer'];
                    contexts.push(indices.map((i : any) => question_template['choices'][i]));
                } else if (question_response['booleanAnswer'] !== undefined) {
                    // indices is boolean
                    const wearingHearingAids = question_response['booleanAnswer'];
                    contexts.push(wearingHearingAids ? 'Hearing aids' : 'No hearing aids')
                }
            }

            contexts.join(', ');

            let challenges : JSX.Element[] = [];

            for (let grid_result of this.props.survey.associated_survey['reasons']) {
                const category = grid_categories[grid_result['reasonType'] as GridCategoryName];
                
                challenges.push(<span className='challenge' style={{ backgroundColor : category.color}}>{grid_result['title']}</span>);
            } 

            return  <div className='grid-survey'> 
                        <span className='challenges'>{challenges}</span>
                        <span className='context'>{contexts.join(', ')}</span>
                    </div>            
        } catch (e) {
            console.log("problem parsing results", e);
        }
    }
};

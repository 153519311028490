import React from 'react';

import { Button, Modal, Input, Result } from 'antd';

import '../../App.css';
import * as API from '../../api';


interface NewSurveyTemplateProps {
  refresh: Function;
}

interface NewSurveyTemplateState {
  visible: boolean;
  name: string;
  file?: any;
  errorText: string;
  success: boolean;
}

export default class CreateNewSurveyTemplate extends React.Component<NewSurveyTemplateProps, NewSurveyTemplateState> {
  loading: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      name: "",
      errorText: "",
      success: false
    };

    this.loading = false
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  hideModal = () => {
    this.setState({ visible: false });
  }

  handleSubmit = async () => {
    if (this.loading) return

    this.loading = true;
    this.forceUpdate();

    //let response = await API.createNewStudy(this.state.name, this.state.description);


    //redirect to urls
    /*if (response.ok) {
      let data = await response.json();
      window.location.href = `study/${data['id']}`;
    } else {
      this.loading = false;
      this.setState({ errorText : "There was a problem creating a new study."});
    }*/
  }

  readFile = async (uri: any) => {
    const reader = new FileReader();
    reader.readAsText(uri);
    reader.onload = () => {
      try {
        let data = JSON.parse(reader.result as string);
        this.setState({ file: data, name: data.identifier });
      }
      catch (e) {
        this.setState({ errorText : 'Error parsing JSON file. Please make sure file is valid JSON.' })
      }
    }
  }

  resetForm = async () => {
    this.setState({ file: undefined, name: "", success: false });
  }


  uploadTemplate = async () => {

    if (!this.state.name) this.setState({ errorText: "Please add a name!" });
    else if (!this.state.file) this.setState({ errorText: "Please select a file!" });

    let response = await API.createSurveyTemplate(this.state.name, this.state.file);
    if (response.ok) {
      //let data = await response.json();
      //this.setState({templates : data["templates"]}); // TODO: add success
      this.setState({ success: true });
      this.props.refresh();
    }
    else if (response.status === 409) {
      this.setState({ errorText: "Template with name exists." });
    }
    else {
      this.setState({ errorText: "Error creating new template." });
      console.log('error', response)
    }
  }

  render() {
    let body;
    if (this.state.success) {
      body = <Result
        status="success"
        title="Success!"
        extra={[
          <Button type="primary" onClick={this.resetForm}>
            Add another
          </Button>,
        ]}
      />
    }
    else {
      body = <> <p className='config-modal-description'>The identifer is a string that specifies the survey in the study schedule. This is read
        from the survey JSON, and should consist of a unique alphanumeric string with no spaces.
      </p>
        <Input value={this.state.name} placeholder='identifier'
          onChange={e => this.setState({ name: e.target.value })}
          readOnly={true} disabled={true} />
        <Input type='file' onChange={e => this.readFile(e.target.files![0])} style={{ margin: '10px 0' }} />
        <div className='error-text'>{this.state.errorText}</div>

        <Button onClick={this.uploadTemplate}>Upload</Button></>
    }

    return (
      <>
        <Button className='new-user-btn' type='primary'
          style={{ 'marginBottom': 20 }} onClick={this.showModal}>
          + Add Template
        </Button>

        <Modal
          open={this.state.visible}
          title="Create New Template"
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={null}
        >
          {body}

        </Modal>
      </>
    );
  }
}



import React from 'react';

import User from '../../models/UserModel';
import Study from '../../models/StudyModel';

import AppLayout from '../AppLayout';
import ListOfUsers from '../ListOfUsers';
import StudyInfo from './StudyInfo';
import Error404 from '../Error404';

import '../../App.css';
import * as API from '../../api';

interface StudyState {
  study? : Study;
  users : Array<User>;
  error : boolean;
  stats : Array<any>;
}

interface ViewProps {
  current_user : User;
}

export default class StudyView extends React.Component<ViewProps, StudyState> {
  constructor(props : any) {
    super(props);
    this.state = { users : [], 
                   stats : [],
                   error : false };
  }

  async componentDidMount() {
    const study_id = parseInt(window.location.href.split("/").pop()!);

    let users_response = await API.getStudyParticipants(study_id);
    let user_data = await users_response.json();

    let study_response = await API.getStudyByID(study_id);
    let study_data = await study_response.json();

    let stats_response = await API.getStudySurveyStats(study_id);
    let stats = await stats_response.json();
    console.log(stats)

    let config_data;
    try {
      let config_response = await API.getStudyConfig(study_id);
      if (config_response.ok) config_data = await config_response.json();
    } catch (e) {
      config_data = undefined;
    }
    
    let study = new Study(study_data);
    study.config = config_data;

    if (study_response.status !== 200) {
      this.setState({ error : true });
      return;
    }

    this.setState({ study : study, users : user_data.map((u : any) => new User(u)), stats : stats })
  }

  updateConfig = (new_config : any) => {
    let new_study = this.state.study;
    new_study!.config = new_config;

    this.setState({study : new_study});
  }

  refreshParticipants = async () => {
    const study_id = parseInt(window.location.href.split("/").pop()!);

    let users_response = await API.getStudyParticipants(study_id);
    let user_data = await users_response.json();

    this.setState({ users : user_data.map((u : any) => new User(u))})
  }


  render() {
    if (this.state.error) return <Error404 current_user={this.props.current_user}/>;
    if (!this.state.study) return null;
    
    return (
      <AppLayout crumbs={["My Studies", this.state.study.name]} 
                 className='study-view'
                 current_user={this.props.current_user}>
        <StudyInfo study={this.state.study} refreshParticipants={this.refreshParticipants} 
                                            updateConfig={this.updateConfig}/>
        <ListOfUsers data={this.state.users} current_study={this.state.study} stats={this.state.stats}/>
      </AppLayout>
    );
  }
}


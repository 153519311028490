import React from 'react';

import { Button, Modal, Form, Input, Select, Typography } from 'antd';

import '../App.css';
import * as API from '../api';
import Study from '../models/StudyModel';

const { Text } = Typography;



interface CreateNewParticipantState {
  visible: boolean;
  name: string;
  email: string;
  study_id: number;
  password: string;
  errorText: string;
  created_user?: any;
}

interface CreateNewParticipantProps {
  studies: Array<Study>;
  chooseStudy?: boolean;
  refreshParticipants : Function;
}

export default class CreateNewParticipant extends React.Component<CreateNewParticipantProps, CreateNewParticipantState> {
  loading: boolean;

  static defaultProps = {
    chooseStudy: false
  };

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      name: "",
      email: "",
      errorText: "",
      study_id: this.props.studies[0]?.study_id,
      password: Math.random().toString(36).slice(2, 16),
    };

    this.loading = false
  }


  showModal = () => {
    this.setState({ visible: true });

    if (!this.state.study_id) this.setState({ study_id: this.props.studies[0]?.study_id })
  }

  hideModal = () => {
    this.loading = false;
    this.setState({ visible: false, name: "", email: "", 
                    password: Math.random().toString(36).slice(2, 16), 
                    errorText : "", created_user : undefined })
  }

  handleSubmit = async () => {
    if (this.loading) return;

    this.loading = true;
    this.forceUpdate();

    let response = await API.createNewParticipant(this.state.name, this.state.email, this.state.password, this.state.study_id);

    //redirect to urls
    if (response.ok) {
      let data = await response.json();
      this.setState({created_user : data});
      this.props.refreshParticipants();
      this.loading = false;

    } else {
      this.loading = false;
      this.setState({ errorText: "There was a problem creating a new user." });
    }
  }

  render() {
    let body;
    if (!this.props.studies) return;
    if (this.state.created_user) {
      body = (<div className='invite-modal-body'>
        <p className='config-modal-description'>Provide the following code to study participant to set up study app on their device.</p>
        <>Code: <Text keyboard copyable>{this.state.created_user.code.code}</Text></><br />
        <>Created: <Text keyboard>{this.state.created_user.code.created_on}</Text></><br />
        <>Expiration: <Text keyboard>{this.state.created_user.code.expiration}</Text></><br />
      </div>)
    }
    else {
      body =
        <Form
          name="basic"
          autoComplete="off"
        >
          <div className='error-text'>{this.state.errorText}</div>

          <Form.Item
            label="Study"
            name="study"
            initialValue={this.props.studies[0]?.study_id}
          >
            <Select
              options={this.props.studies.map((e: any) => { return { value: e.study_id, label: e.name } })}
              value={this.state.study_id}
              onChange={e => this.setState({ study_id: e })}
              disabled={!this.props.chooseStudy}
            />
          </Form.Item>

          <Form.Item
            label="Participant name"
            name="name"
            rules={[{ required: true, message: 'Please input a name!' }]}
          >
            <Input value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
          </Form.Item>

          <Form.Item
            label="Participant email"
            name="email"
            rules={[{ required: true, message: 'Please input an email!' }]}
          >
            <Input value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
          </Form.Item>

          <Form.Item
            label="Participant password"
            name="password"
            initialValue={this.state.password}
            rules={[{ required: true, message: 'Please input a password!' }]}
          >
            <Input value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
          </Form.Item>

          <Form.Item >
            <span className='study-modal-actions'>
              <Button onClick={this.hideModal}>Cancel</Button>
              <Button type="primary" onClick={this.handleSubmit} loading={this.loading}>Submit</Button>
            </span>
          </Form.Item>
        </Form>

    }

    return (
      <>
        <Button type="primary" className="new-user-btn" onClick={this.showModal}>
          + Add New Participant
        </Button>
        <Modal
          open={this.state.visible}
          title="Create New Participant"
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={null}
        >
          {body}

        </Modal>
      </>
    );
  }
}




import React from 'react';

interface IProps {
    fallback : React.ReactNode;
    children : React.ReactNode;
}

interface IState {
    hasError : boolean
} 


export default class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props : IProps) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error : Error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void { 
        console.log('caught',error)
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return this.props.fallback;
      }
  
      return this.props.children;
    }
  }
import '../../App.css';

import React from 'react';
import { CategoryStats, ChallengeStats, GridCategoryName, ListeningStats, LocationStats, grid_categories } from '../../models/types';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';


const ChallengeCategoryBar: React.FC<{ data: CategoryStats[] }> = ({ data }: { data: CategoryStats[] }) => {
  const renderPercentageLabel = (props: any) => {
    const { x, y, height, value } = props;

    if (value === '0') return null;

    return (
      <g>
        <text x={x + 25} y={y + (height / 2)} fill="#fff" style={{ font: 'bold 14pt sans-serif' }}
          textAnchor="middle" dominantBaseline="middle">
          {`${value}%`}
        </text>
      </g>
    );
  };

  const renderChallengesLabel = (props: any) => {
    const { x, y, height, value } = props;

    if (value === '0') return null;

    return (
      <g>
        <text x={x + 4} y={y + (height) + 4} fill="black" style={{ font: ' 10pt sans-serif', opacity: 0.5 }}
          textAnchor="left" dominantBaseline="hanging">
          {value.map((c: ChallengeStats) => `${c.challenege} (${c.count})`).join(', ')}
        </text>
      </g>
    );
  };

  const renderTickLabel = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g>
        <text x={x} y={y + 6} textAnchor="end" style={{ font: 'bold 14pt sans-serif' }}
          fill={grid_categories[payload.value as GridCategoryName].color} >
          {grid_categories[payload.value as GridCategoryName].displayName}
        </text>
      </g>
    );
  };


  return <div key={'challenges-category'}>
    <h4 className='graph-title'>Challenges by Category</h4>
    <ResponsiveContainer className='category-stats' width={"100%"} minHeight={400} key={'Challenges'}>
      <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} layout="vertical"
        barCategoryGap={30}>

        <XAxis dataKey='count' name={'count'} unit={''} type='number' allowDecimals={false}
          domain={[0, 'dataMax + 2']} />
        <YAxis dataKey='category' type='category' tick={renderTickLabel} />
        <Tooltip formatter={(value: any, name: any, props: any) => [`${value}`]} labelFormatter={() => ''}
          itemStyle={{ color: 'black', opacity: 0.8 }} />
        <Bar dataKey="count" fill="#8884d8" barSize={45}>
          <LabelList dataKey="percentage" content={renderPercentageLabel} />
          <LabelList dataKey="challenges" content={renderChallengesLabel} />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={grid_categories[entry.category as GridCategoryName]["color"]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
}

const TopChallengesBar: React.FC<{ data: ChallengeStats[], total_challenges: number }> = ({ data, total_challenges }: { data: ChallengeStats[], total_challenges: number }) => {

  const num = 6;
  data = data.slice(0, num);

  const renderPercentageLabel = (props: any) => {
    const { x, y, height, width, value } = props;

    if (value === '0') return null;

    return (
      <g>
        <text x={x + 25} y={y + (height / 2)} fill="#fff" style={{ font: 'bold 14pt sans-serif' }}
          textAnchor="middle" dominantBaseline="middle">
          {`${((value / total_challenges) * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  return <div key={'top-challenges'}>
    <h4 className='graph-title'>Top Challenges</h4>
    <ResponsiveContainer className='category-stats' width={"100%"} minHeight={300} key={'Challenges'}>
      <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} layout="vertical"
        barCategoryGap={30}>

        <XAxis dataKey='count' name={'count'} unit={''} type='number' allowDecimals={false}
          domain={[0, 'dataMax + 2']} />
        <YAxis dataKey='challenege' type='category' />
        <Tooltip formatter={(value: any, name: any, props: any) => [`${value}`]} labelFormatter={() => ''}
          itemStyle={{ color: 'black', opacity: 0.8 }} />
        <Bar dataKey="count" fill="#8884d8" barSize={45}>
          <LabelList dataKey="count" content={renderPercentageLabel} />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={grid_categories[entry.category as GridCategoryName]["color"]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
}

const LocationContextStackedBar: React.FC<{ data: LocationStats[], total_challenges: number }> = ({ data, total_challenges }: { data: LocationStats[], total_challenges: number }) => {

  let formatted_data = [];
  for (let location of data) {
    const category_occurrences = location.challenege_categories.reduce((obj: any, item) => {
      obj[item] = (obj[item] || 0) + 1;
      return obj;
    }, {});

    let total = 0;
    const challenge_occurrences = location.challenges.reduce((obj: any, item: any) => {
      let existing = obj.find((i: any) => i.challenge === item.challenge);

      total += 1;
      if (existing) {
        existing.count += 1;
      } else {
        obj.push({ count: 1, ...item });
      }

      return obj;
    }, []);

    formatted_data.push({
      location: location.location, challenges: challenge_occurrences,
      total: total, ...category_occurrences
    });
  }

  const CustomTooltip = ({ active, payload, label }: { active?: any, payload?: any, label?: string }) => {
    if (active && payload && payload.length) {
      return (
        <div className="context-tooltip">
          {<span className="label">{`${label}`}</span>}
          {payload[0].payload.challenges.map((c: any) =>
          (<span className='challenge-item' key={c.challenge}
            style={{ backgroundColor: grid_categories[c.category as GridCategoryName]["color"] }}>
            {c.challenge} ({c.count})
          </span>))}

        </div>
      );
    }

    return null;
  };

  const renderPercentageLabel = (props: any) => {
    const { x, y, height, width, value } = props;

    if (value === 0) return null;

    return (
      <g>
        <text x={x + width + 22} y={y + (height / 2)} fill="#0000008c" style={{ font: ' 14pt sans-serif' }}
          textAnchor="middle" dominantBaseline="middle">
          {`${((value / total_challenges) * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  const renderTickLabel = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g width={50}>
        <text x={x} y={y + 6} textAnchor="end" style={{maxWidth : 50, color: '#666666'}} fill='#666666'>
          {payload.value}
        </text>
      </g>
    );
  };

  return <div key={'location'}>
    <h4 className='graph-title'>Location Context</h4>
    <ResponsiveContainer className='category-stats' width={"100%"} minHeight={380} key={'Challenges'}>
      <BarChart data={formatted_data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} layout="vertical"
        barCategoryGap={30}>

        <XAxis type='number' allowDecimals={false} domain={[0, (dataMax:any) => (2 * Math.round(dataMax / 2))+2]} />
        <YAxis dataKey='location' type='category' tick={renderTickLabel}/>
        <Tooltip itemStyle={{ color: 'black', opacity: 0.8 }} content={<CustomTooltip />} />

        <Bar dataKey="sound" stackId="a" fill="#41DEBB" barSize={35} />
        <Bar dataKey="space" stackId="a" fill="#32ADE6" barSize={35} />
        <Bar dataKey="mood" stackId="a" fill="#FFB349" barSize={35} />
        <Bar dataKey="social" stackId="a" fill="#FF8E9B" barSize={35} />
        <Bar dataKey="custom" stackId="a" fill="#A357D7" barSize={35}>
          <LabelList dataKey="total" content={renderPercentageLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
}

const ListeningContextStackedBar: React.FC<{ data: ListeningStats[], total_challenges: number }> = ({ data, total_challenges }: { data: ListeningStats[], total_challenges: number }) => {

  let formatted_data = [];
  for (let listening of data) {
    const category_occurrences = listening.challenege_categories.reduce((obj: any, item) => {
      obj[item] = (obj[item] || 0) + 1;
      return obj;
    }, {});

    let total = 0;

    const challenge_occurrences = listening.challenges.reduce((obj: any, item: any) => {
      let existing = obj.find((i: any) => i.challenge === item.challenge);
      total += 1;
      if (existing) {
        existing.count += 1;
      } else {
        obj.push({ count: 1, ...item });
      }

      return obj;
    }, []);

    formatted_data.push({
      listening: listening.listening, challenges: challenge_occurrences,
      total: total, ...category_occurrences
    });
  }

  const CustomTooltip = ({ active, payload, label }: { active?: any, payload?: any, label?: string }) => {
    if (active && payload && payload.length) {
      return (
        <div className="context-tooltip">
          {<span className="label">{`${label}`}</span>}
          {payload[0].payload.challenges.map((c: any) =>
          (<span className='challenge-item' key={c.challenge}
            style={{ backgroundColor: grid_categories[c.category as GridCategoryName]["color"] }}>
            {c.challenge} ({c.count})
          </span>))}

        </div>
      );
    }

    return null;
  };

  const renderPercentageLabel = (props: any) => {
    const { x, y, height, width, value } = props;

    if (value === 0) return null;

    return (
      <g>
        <text x={x + width + 22} y={y + (height / 2)} fill="#0000008c" style={{ font: ' 14pt sans-serif' }}
          textAnchor="middle" dominantBaseline="middle">
          {`${((value / total_challenges) * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  const renderTickLabel = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g width={50}>
        <text x={x} y={y + 6} textAnchor="end" style={{maxWidth : 50, color: '#666666'}} fill='#666666'>
          {payload.value}
        </text>
      </g>
    );
  };

  return <div key={'listening'}>
    <h4 className='graph-title'>Listening Context</h4>
    <ResponsiveContainer className='category-stats' width={"100%"} minHeight={340} key={'Challenges'}>
      <BarChart data={formatted_data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} layout="vertical"
        barCategoryGap={30}>

        <XAxis type='number' allowDecimals={false} domain={[0, (dataMax:any) => (2 * Math.round(dataMax / 2))+2]} />
        <YAxis dataKey='listening' type='category' tick={renderTickLabel}/>
        <Tooltip itemStyle={{ color: 'black', opacity: 0.8 }} content={<CustomTooltip />}  />

        <Bar dataKey="sound" stackId="a" fill="#41DEBB" barSize={40} />
        <Bar dataKey="space" stackId="a" fill="#32ADE6" barSize={40} />
        <Bar dataKey="mood" stackId="a" fill="#FFB349" barSize={40} />
        <Bar dataKey="social" stackId="a" fill="#FF8E9B" barSize={40} />
        <Bar dataKey="custom" stackId="a" fill="#A357D7" barSize={40}>
          <LabelList dataKey="total" content={renderPercentageLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
}


export { ChallengeCategoryBar, TopChallengesBar, LocationContextStackedBar, ListeningContextStackedBar };

import React from 'react';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import StudyInviteModal from './StudyInviteModal';
import Study from '../../models/StudyModel';

interface OptionsProps {
  study : Study;
  exportHealth : Function;
}


const StudyOptionsMenu: React.FC<OptionsProps> = ({study, exportHealth} : OptionsProps) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <StudyInviteModal study={study} />,
    },
    {
      key: '2',
      label: <div onClick={() => exportHealth()}>Export all health data</div>
    }
  ];

  return (<Dropdown menu={{ items }} className='study-dropdown'>
            <Button><EllipsisOutlined /></Button>
          </Dropdown>)
}

export default StudyOptionsMenu;
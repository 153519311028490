import React from 'react';

import { Button, Modal, Typography } from 'antd';

import '../../App.css';
import * as API from '../../api';
import User from '../../models/UserModel';
import { CodeOutlined } from '@ant-design/icons';

const { Text } = Typography;



interface UserOTPModalState {
  visible: boolean;
  code: any;
  expiration: string;
  errorText : string;
}

interface UserOTPModalProps {
  user: User;
}

export default class UserOTPModal extends React.Component<UserOTPModalProps, UserOTPModalState> {
  loading: boolean;

  static defaultProps = {
    chooseStudy: false
  };

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      code : null,
      expiration : "",
      errorText : ""
    };

    this.loading = false
  }


  showModal = async () => {
    let response = await API.getParticipantOTP(this.props.user.user_id);

    if (response.ok) {
      let data = await response.json();
      this.setState({code : data})

    } else {
      this.loading = false;
      this.setState({ errorText: "There was a problem getting the code." });
    }


    this.setState({ visible: true });
  }

  hideModal = () => {
    this.setState({ visible: false })
  }

  handleRefresh = async () => {
    if (this.loading) return;

    this.loading = true;
    this.forceUpdate();

    let response = await API.refreshParticipantOTP(this.props.user.user_id);

    //redirect to urls
    if (response.ok) {
      let data = await response.json();
      this.setState({code : data})

    } else {
      this.setState({ errorText: "There was a problem creating a new code." });
    }
    this.loading = false;
  }

  render() {
    //if (!this.state.code) return <Button onClick={this.showModal} className='code-modal-btn'><CodeOutlined /></Button>

    let body;
    if (this.state.code) {
      body = <div className='invite-modal-body'>
              <>Code: <Text keyboard copyable>{this.state.code.code}</Text></><br />
              <>Created: <Text keyboard>{this.state.code.created_on}</Text></><br />
              <>Expiration: <Text keyboard>{this.state.code.expiration}</Text></><br />
            </div>

    }

    return (
      <>
        <Button onClick={this.showModal} className='code-modal-btn'><CodeOutlined />Participant Login Code</Button>
        <Modal
          open={this.state.visible}
          title="Participant Login Code"
          onCancel={this.hideModal}
          footer={null}
        >
          <p className='config-modal-description'>Provide the following code to study participant to set up study app on their device.</p>
          {body}
          <Button type='primary' onClick={this.handleRefresh} className='code-btn'>Generate New Code</Button>

        </Modal>
      </>
    );
  }
}



import { useEffect, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { LogData } from '../../models/types';
import { DateText } from '../DateText';
import '../../App.css';
import { Table } from 'antd';
import * as API from '../../api';
import Study from '../../models/StudyModel';


interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface DataTableProps {
  user_id : string;
  study? : Study;
}

const LogDataTable = (props : DataTableProps) => {
  const [log_data, setData] = useState<LogData[]>();
  const [sort, setSort] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 100,
      total : 0,
      showTotal: total => `${total} total`,
      showSizeChanger : false
    },
    filters : {"data_label" : []}
  });

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const fetchData = async () => {
    setLoading(true);
    let logs_response = await API.getUserLogs(props.user_id, tableParams.pagination?.current,
                                                tableParams.pagination?.pageSize, sort);
    
    let logs_data = await logs_response.json();

    setData(logs_data.logs);
    
    setLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: logs_data.total_items,
      },
    });
  };

  const createSortParam = (sort : SorterResult<LogData> | SorterResult<LogData>[]) => {
    let newSorting : SorterResult<LogData>;
    if (Array.isArray(sort)) newSorting = sort[0];
    else newSorting = sort;

    if (newSorting.order === undefined) {
      return '';
    }
    else if (newSorting.order === 'ascend') {
      return `${newSorting.field}`
    } 
    else if (newSorting.order === 'descend') {
      return `-${newSorting.field}`
    }
    else {
      return '';
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<LogData> | SorterResult<LogData>[]) => {
      setTableParams({
        pagination,
        ...filters,
        ...sorter,
      });

      let newSort = createSortParam(sorter);

      setSort(newSort);

      if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        setData([]);
      }
  };

  const columns: ColumnsType<LogData> = [
    {
      title: 'Message',
      dataIndex: 'message',
      width: '30%',
    },
    {
      title: 'Created On',
      width: '30%',
      dataIndex : 'created_on',
      sorter: true,
      render: (o : string) => <DateText date={new Date(o)}/>
    },
  ];


  return (
    <Table columns={columns} rowKey="created_on" loading={loading} 
           onChange={handleTableChange}
           pagination={tableParams.pagination}
           dataSource={log_data} size={'small'}/>
  );
  
}

export default LogDataTable;


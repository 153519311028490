import moment from 'moment';


interface DateProps {
    date : Date;
    fallback? : string;
}


export const DateText = ({date, fallback = ''}: DateProps) => {
    if (date.getTime() !== new Date(0).getTime()) return <>{moment(date).format('YYYY-MM-DD hh:mm:ssa')}</>
    else return <>{fallback}</>;
}
    
